/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { ExtendedTooltip, FlexContainer, SVGIcon } from "@nordcloud/gnui";

type Props = {
  message?: string;
};

export function NoAccessToEditingTooltip({ message = "You are not an admin of this organization" }: Props) {
  return (
    <TooltipWrapper justifyContent="center">
      <ExtendedTooltip caption={message} placement="left">
        <SVGIcon name="info" />
      </ExtendedTooltip>
    </TooltipWrapper>
  );
}

const TooltipWrapper = styled(FlexContainer)`
  > * {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
  }
`;
