/*
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldError, FieldErrorsImpl } from "react-hook-form";
import { When } from "react-if";
import { Spacer, Text, theme } from "@nordcloud/gnui";
import { isNotEmpty, isNotNil } from "~/tools";

type Props = {
  error?: FieldError | FieldErrorsImpl<{ [x: string]: unknown }>;
};

export function FormError({ error }: Props) {
  const errors = (Array.isArray(error) ? error : [error]).filter(isNotNil).filter(hasMessage);
  const hasErrors = isNotEmpty(errors);

  return (
    <When condition={hasErrors}>
      <Spacer height={theme.spacing.spacing01} />
      {errors.map((err, index) => (
        <Text
          key={`${err.message}-${index}`}
          color="danger"
          weight="medium"
          size="sm"
          mt={theme.spacing.spacing01}
          mb="0"
        >
          {err.message}
        </Text>
      ))}
    </When>
  );
}

// Type guard for errors. We want to ensure that any error has a message attached.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasMessage(item: any): item is { message: string } {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
  return isNotEmpty(item?.message ?? "");
}
