/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const SolidLine = styled.div<{ color: string }>`
  width: ${theme.spacing.spacing08};
  background-color: ${({ color }) => color};
  height: 2px;
`;
