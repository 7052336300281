/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  &:active {
    svg {
      fill: ${theme.color.text.text04};
    }
  }
`;
