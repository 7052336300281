/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps } from "react";
import { useToggle } from "react-use";
import styled from "styled-components";
import { Box, FlexContainer, SVGIcon, Text } from "@nordcloud/gnui";
import { WithChildren } from "~/tools";

type Props = ComponentProps<typeof Box> &
  WithChildren & {
    title: string;
    opened?: boolean;
    maxHeight?: string;
  };

export function CollapsibleBox({ children, title, opened = true, maxHeight = "2000px", ...boxProps }: Props) {
  const [isExpanded, toggleIsExpanded] = useToggle(opened);

  return (
    <Box {...boxProps}>
      <FlexContainer justifyContent="space-between" alignItems="start">
        <Text weight="medium" as="header" mb={0}>
          {title}
        </Text>
        <ClickableSVGIcon name={isExpanded ? "chevronUp" : "chevronDown"} onClick={toggleIsExpanded} />
      </FlexContainer>
      <Wrapper expanded={isExpanded} maxHeight={maxHeight}>
        {children}
      </Wrapper>
    </Box>
  );
}

const ClickableSVGIcon = styled(SVGIcon)`
  cursor: pointer;
`;

const Wrapper = styled.div<{ expanded: boolean; maxHeight: string }>`
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight : "0")};
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  transition: ${({ expanded }) =>
    expanded
      ? "max-height 0.4s ease-in-out, opacity 0.2s linear 0.4s"
      : "max-height 0.4s ease-in-out 0.1s, opacity 0.2s linear"};
`;
