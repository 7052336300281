/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import { Button, FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { noop } from "~/tools";
import { Admin, AdminFields } from "./types";

type Props = {
  admins: Admin[];
  onRemove?: (index: number) => void;
  onEdit?: (index: number) => void;
  isEditable?: boolean;
  isRemovable?: boolean;
};

export function AdminSummary({
  admins,
  onRemove = noop,
  onEdit = noop,
  isEditable = false,
  isRemovable = false,
}: Props) {
  return (
    <Grid>
      {admins.map((admin, index) => (
        <FlexContainer
          key={`${admin[AdminFields.MAIL]}-${index}`}
          justifyContent="space-between"
          css={{
            cursor: "default",
            background: theme.color.background.ui03,
            border: `1px solid ${theme.color.border.border01}`,
            padding: theme.spacing.spacing03,
            boxShadow: theme.shadow.shadow00,
          }}
        >
          <FlexContainer columnGap={theme.spacing.spacing04}>
            <SVGIcon name="userAccess" />
            <FlexContainer direction="column" alignItems="start">
              <Text mb={0}>
                {admin[AdminFields.NAME]} {admin[AdminFields.SURNAME]}
              </Text>
              <Text size="sm" color={theme.color.text.text02} mb={0}>
                {admin[AdminFields.MAIL]}
              </Text>
            </FlexContainer>
          </FlexContainer>
          <When condition={isEditable || isRemovable}>
            <FlexContainer columnGap={theme.spacing.spacing01}>
              <When condition={isEditable}>
                <Button type="button" icon="edit" size="sm" severity="low" onClick={() => onEdit(index)} />
              </When>
              <When condition={isRemovable}>
                <Button type="button" icon="trash" size="sm" severity="low" onClick={() => onRemove(index)} />
              </When>
            </FlexContainer>
          </When>
        </FlexContainer>
      ))}
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.spacing.spacing02} ${theme.spacing.spacing03};
`;
