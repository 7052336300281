/*
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const List = styled.ul`
  & > li {
    padding: ${theme.spacing.spacing03};
    border-top: 1px solid ${theme.color.border.border01};

    &:nth-child(1) {
      border-top-width: 2px;
    }

    &:nth-child(2n) {
      background: ${theme.color.background.ui01};
    }

    &:nth-child(2n-1) {
      background: ${theme.color.background.ui02};
    }
  }
`;
