/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { FlexContainer, Text } from "@nordcloud/gnui";
import { Description } from "~/components/Styled";
import { dateFormat } from "~/tools";

type Props = {
  creationDate: string;
  entity: string;
};

export function CreationDate({ creationDate, entity }: Props) {
  return (
    <FlexContainer direction="column" alignItems="start">
      <Description css={{ lineHeight: 1 }}>{entity} Creation Date</Description>
      <Text mb={0}>{dayjs(new Date(Number(creationDate)).toISOString()).format(dateFormat.dayMonthShortYear)}</Text>
    </FlexContainer>
  );
}
