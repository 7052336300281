/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback } from "react";
import { CSSProp } from "styled-components";
import { ExtendedTooltip, FlexContainer, SVGIcon, theme } from "@nordcloud/gnui";
import { PathName } from "~/tools";

type Props = {
  status: "DISABLED" | "ENABLED" | "MARKED_FOR_DELETION" | null;
};

export function StatusIcon({ status }: Props) {
  const icon = useCallback(getIcon, [status]);
  const css = useCallback(getCss, [status]);
  const tooltipCaption = useCallback(mapStatus, [status]);

  return (
    <ExtendedTooltip caption={tooltipCaption(status)}>
      <FlexContainer>
        <SVGIcon name={icon(status)} css={css(status)} />
      </FlexContainer>
    </ExtendedTooltip>
  );
}

function getIcon(status: Props["status"]): PathName {
  switch (status) {
    case "DISABLED":
      return "statusError";
    case "ENABLED":
      return "statusSuccess";
    case "MARKED_FOR_DELETION":
      return "statusWarning";
    default:
      return "statusError";
  }
}

function getCss(status: Props["status"]): CSSProp | undefined {
  if (status === "DISABLED") {
    return { "> circle": { fill: theme.color.support.grey } };
  }
}

function mapStatus(status: Props["status"]) {
  switch (status) {
    case "ENABLED":
      return "Active";
    case "DISABLED":
      return "Inactive";
    case "MARKED_FOR_DELETION":
      return "Pending for Deletion Approval";
    default:
      return null;
  }
}
