/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { WithChildren } from "~/tools";
import { useAccess } from "./AccessProvider";
import { Role } from "./Role";

type Props = WithChildren & {
  roles: Role[];
  fallback?: ReactNode;
};

export function HasRole({ roles, fallback = null, children }: Props) {
  const { role } = useAccess();

  if (roles.includes(role)) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
}
