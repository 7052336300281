/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

type Props = {
  admins: { name: string; surname: string }[];
};

export function AdminTooltip({ admins }: Props) {
  return (
    <div>
      {admins.map((admin) => (
        <p key={`${admin.name}-${admin.surname}`} css={{ marginBottom: 0 }}>
          {admin.name} {admin.surname}
        </p>
      ))}
    </div>
  );
}
