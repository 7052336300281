/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps, ReactNode } from "react";
import { When } from "react-if";
import { FlexContainer, Label, Spacer, Text, theme } from "@nordcloud/gnui";
import { FormError } from "./FormError";

type Props = {
  label: string;
  caption?: string;
  isRequired?: boolean;
  children: ReactNode;
  error?: ComponentProps<typeof FormError>["error"];
};

export function FormItem({ label, caption, isRequired = true, children, error }: Props) {
  const hasCaption = caption != null;

  return (
    <FlexContainer direction="column" alignItems="start" css={{ width: "100%" }}>
      <Label name={label} required={isRequired} css={{ marginBottom: 0 }} />
      <When condition={hasCaption}>
        <Text size="sm" color={theme.color.text.text02} mb={0}>
          {caption}
        </Text>
      </When>
      <Spacer height={theme.spacing.spacing02} />
      <div css={{ width: "100%" }}>{children}</div>
      <FormError error={error} />
    </FlexContainer>
  );
}
