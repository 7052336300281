/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, SVGIconProps, theme } from "@nordcloud/gnui";

type Props = {
  disableSubmit?: boolean;
  onPrevClick?: () => void;
  submitText?: string;
  icon?: SVGIconProps["name"] | null;
  iconPosition?: "left" | "right";
  initialState?: "loading";
};

export function FormFooter({
  onPrevClick,
  disableSubmit = false,
  submitText = "Next Step",
  icon = "arrowRight",
  iconPosition = "right",
  initialState,
}: Props) {
  const submitButton =
    icon === null ? (
      <Button type="submit" disabled={disableSubmit} initialState={initialState}>
        {submitText}
      </Button>
    ) : (
      <Button
        iconRight={iconPosition === "right"}
        icon={icon}
        type="submit"
        disabled={disableSubmit}
        initialState={initialState}
      >
        {submitText}
      </Button>
    );

  return (
    <Wrapper css={{ justifyContent: "center" }}>
      {onPrevClick && (
        <Button
          icon="arrowLeft"
          type="button"
          severity="medium"
          css={{
            position: "absolute",
            left: theme.spacing.spacing04,
            top: "50%",
            transform: "translateY(-50%)",
            borderColor: "transparent",
          }}
          onClick={onPrevClick}
        >
          Previous Step
        </Button>
      )}
      {submitButton}
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${theme.spacing.spacing04};
  padding-bottom: 0;
  border-top: 1px solid ${theme.color.border.border01};
  position: relative;
`;
