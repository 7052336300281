/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { ComponentProps } from "react";
import * as ReactDOM from "react-dom";
import { ItemsContainer, ItemsSection, MenuItem, NavigationBar } from "@nordcloud/gnui";
import { HasRole } from "~/services/access";
import { MenuLink } from "./components";
import { getItems } from "./config";

export function MenuBar() {
  return ReactDOM.createPortal(<MenuBarVisual />, document.body);
}

function MenuBarVisual() {
  const items = getItems();

  const popoverConfig: ComponentProps<typeof NavigationBar>["popoverConfig"] = {
    position: "end",
    triggerOn: "hover",
    closeOn: "hover",
  };

  return (
    <NavigationBar popoverConfig={popoverConfig}>
      <ItemsContainer>
        <ItemsSection>
          {items.map((item) => (
            <HasRole key={item.caption} roles={item.roles}>
              <MenuLink to={item.route ?? "/"}>
                <MenuItem {...item} />
              </MenuLink>
            </HasRole>
          ))}
        </ItemsSection>
      </ItemsContainer>
    </NavigationBar>
  );
}
