/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const enum AdminFields {
  ID = "id",
  NAME = "name",
  SURNAME = "surname",
  MAIL = "email",
}

export type Admin = {
  [AdminFields.ID]?: string;
  [AdminFields.NAME]: string;
  [AdminFields.SURNAME]: string;
  [AdminFields.MAIL]: string;
};
