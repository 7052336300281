/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const dateFormat = {
  dayMonthYear: "DD.MM.YYYY",
  dayMonthShortYear: "DD MMM YYYY",
  year: "YYYY",
  yearMonthDay: "YYYY-MM-DD",
};
