/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import logo from "~/assets/images/ninja.png";
import { ROUTES } from "~/routes";

export function Page403() {
  return (
    <Box>
      <FlexContainer
        columnGap={theme.spacing.spacing06}
        css={{ width: "100%" }}
        padding={`${theme.spacing.spacing08} 0`}
        justifyContent="center"
      >
        <img alt="logo" src={logo} css={{ width: "15rem", height: "auto" }} />
        <FlexContainer direction="column" alignItems="start" rowGap={theme.spacing.spacing02}>
          <Text size="lg" mb={0}>
            It seems that you do not have permission to view this page
          </Text>
          <Link to={ROUTES.index}>Take me back</Link>
        </FlexContainer>
      </FlexContainer>
    </Box>
  );
}
