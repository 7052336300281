
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ApiKey": [
      "ApiKeyAttributes",
      "ApiKeySecret"
    ],
    "KlarityApiKey": [
      "KlarityApiKeyAttributes",
      "KlarityApiKeySecret"
    ],
    "KlarityPrincipal": [
      "KlarityPrincipalEmail",
      "KlarityPrincipalKey",
      "KlarityPrincipalOther"
    ]
  }
};
      export default result;
    