/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Box, FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { PathName } from "../../../tools";

type Props = {
  icon: PathName;
  title: string;
  onClick?: () => void;
};

export function PostCreationItem({ icon, title, onClick }: Props) {
  return (
    <HoverableBox boxStyle="lightGrey" onClick={onClick}>
      <FlexContainer
        direction="column"
        rowGap={theme.spacing.spacing03}
        justifyContent="center"
        alignItems="center"
        css={{ height: "100%" }}
      >
        <SVGIcon name={icon} size="xl" />
        <Text align="center">{title}</Text>
      </FlexContainer>
    </HoverableBox>
  );
}

const HoverableBox = styled(Box)`
  cursor: pointer;
  transition: border 0.3s linear;

  &:hover {
    border: ${theme.borderDefault};
  }
`;
