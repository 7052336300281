/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ComponentProps, HTMLProps } from "react";
import { Spacer, theme } from "@nordcloud/gnui";
import { FormFooter } from "./FormFooter";

type Props = HTMLProps<HTMLFormElement> & {
  footerProps: ComponentProps<typeof FormFooter>;
  margin?: string;
  maxWidth?: string;
};

export function FormWrap({ children, footerProps, margin = "0 auto", maxWidth = "924px", ...formProps }: Props) {
  return (
    <form {...formProps}>
      <div
        css={{
          maxWidth,
          margin,
        }}
      >
        {children}
      </div>
      <Spacer height={theme.spacing.spacing05} />
      <FormFooter {...footerProps} />
    </form>
  );
}
