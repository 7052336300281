/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ROUTES } from "~/routes";
import { Role } from "~/services/access";
import { PathName } from "~/tools";

type Items = {
  caption: string;
  icon: PathName;
  roles: Role[];
  route?: string;
};

export const getItems = (): Items[] => [
  {
    caption: "MSP Accounts",
    route: ROUTES.accounts.index,
    icon: "organization",
    roles: [Role.MSP_ADMIN, Role.MSP_PANEL_ADMIN],
  },
  {
    caption: "Organizations",
    route: ROUTES.organizations.index,
    icon: "briefcase",
    roles: [Role.MSP_ADMIN, Role.MSP_PANEL_ADMIN],
  },
];
