/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { isSelected } from "../helpers";

type Props = {
  to: string;
  children: ReactNode;
};

export function MenuLink({ to, children }: Props) {
  const { pathname } = useLocation();

  return (
    <StyledLink to={to} className={isSelected(pathname, to) ? "selected" : ""}>
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  color: ${theme.color.text.text01};
  text-decoration: none;
  width: 100%;

  &:hover,
  &:hover > * {
    background-color: ${theme.color.interactive.secondary};
    border-radius: ${theme.radius.md};
    color: ${theme.color.text.text01};
    text-decoration: none;
  }

  &:active,
  &:active > * {
    background-color: ${theme.color.interactive.secondaryActive};
    border-radius: ${theme.radius.md};
    color: ${theme.color.text.text01};
    text-decoration: none;
  }

  &.selected,
  &.selected > * {
    background-color: ${theme.color.interactive.secondaryHover};
    border-radius: ${theme.radius.md};
    color: ${theme.color.text.text01};
    text-decoration: none;
  }
`;
