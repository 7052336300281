/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

// cache menu bar & layout dimensions
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

const menuWidth = theme.spacing.spacing07;
const menuHorizontalPadding = theme.spacing.spacing03;
const layoutHorizontalSpacing = theme.spacing.spacing04;

// `1st calc`: we need to add menuHorizontalPadding twice because we have
// paddingLeft and paddingRight on the menu element

// `2nd calc`: we need to add layoutHorizontalSpacing additionally to mitigate
// issue with right padding on global application container which is wrongly
// interpreted by the grid
export const ApplicationGrid = styled.div`
  display: grid;
  gap: ${theme.spacing.spacing04} ${layoutHorizontalSpacing};
  grid-template-columns:
    calc(${menuWidth} + 2 * ${menuHorizontalPadding})
    calc(100% - (${menuWidth} + 2 * ${menuHorizontalPadding} + ${layoutHorizontalSpacing}));
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    ". header"
    ". application"
    ". footer";
  width: 100%;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  margin: 0 -${theme.spacing.spacing04} 0 -${layoutHorizontalSpacing};
  grid-area: header;
`;

export const ApplicationWrapper = styled.div`
  grid-area: application;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 2500px) {
    margin: 0 auto;
    max-width: 2500px;
  }
`;

export const FooterWrapper = styled.div`
  grid-area: footer;
  padding-top: ${theme.spacing.spacing05};
`;
