/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { AdminFields } from "./types";

export const adminSchema = z.object({
  [AdminFields.NAME]: z
    .string()
    .min(3, "Name must contain at least 3 characters")
    .max(32, "Name must be shorter than 32 characters"),
  [AdminFields.SURNAME]: z
    .string()
    .min(3, "Surname must contain at least 3 characters")
    .max(32, "Surname must be shorter than 32 characters"),
  [AdminFields.MAIL]: z.string().email("Mail should have correct format"),
});
