/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Page403 } from "./403";
import { HasRole } from "./HasRole";
import { Role } from "./Role";

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  roles: Role[];
};

export function GuardedRoute({ roles, fallback = <Page403 />, children }: Props) {
  return (
    <HasRole roles={roles} fallback={fallback}>
      {children}
    </HasRole>
  );
}
