/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createContext, useContext, useMemo } from "react";
import { useAccessQuery } from "~/generated/graphql";
import { UniversalWrap } from "~/components";
import { WithChildren } from "~/tools";
import { Role } from "./Role";

type Access = {
  accounts: string[];
  role: Role;
};

const AccessContext = createContext<Access>({
  accounts: [],
  role: Role.MSP_ADMIN,
});

export function AccessProvider({ children }: WithChildren) {
  const { data, error, loading } = useAccessQuery();

  const access = useMemo(
    () => ({
      accounts: data?.access.accounts ?? [],
      role: mapRole(data?.access.role ?? Role.MSP_ADMIN),
    }),
    [data?.access.accounts, data?.access.role]
  );

  return (
    <AccessContext.Provider value={access}>
      <UniversalWrap loaderProps={{ loading }} errorProps={{ error }}>
        {children}
      </UniversalWrap>
    </AccessContext.Provider>
  );
}

export function useAccess() {
  const context = useContext(AccessContext);

  if (context === undefined) {
    throw new Error("useMspAccount must be used within a MspAccountProvider");
  }

  return context;
}

type BackendRole = NonNullable<ReturnType<typeof useAccessQuery>["data"]>["access"]["role"];

function mapRole(role: BackendRole): Role {
  switch (role) {
    case "MSP_ADMIN":
      return Role.MSP_ADMIN;
    case "MSP_PANEL_ADMIN":
      return Role.MSP_PANEL_ADMIN;
    default:
      throw new Error("Unknown role");
  }
}
