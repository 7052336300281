/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { generatePath, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Message, Spacer, theme } from "@nordcloud/gnui";
import { inflect } from "~/tools";
import { PostCreationItem } from "./PostCreationItem";

type Props = {
  createdId: string | undefined;
  onCreateNewItem: () => void;
  entity: string;
  detailsUrl: string;
  listUrl: string;
};

export function PostCreation({ createdId, onCreateNewItem, entity, detailsUrl, listUrl }: Props) {
  const navigate = useNavigate();

  const goToDetails = () => navigate(generatePath(detailsUrl, { id: createdId }));

  const goToList = () => navigate(listUrl);

  return (
    <Wrapper>
      <Message status="success" image="success">
        {entity} has been added.
      </Message>
      <Spacer height={theme.spacing.spacing02} />
      <Tiles>
        <PostCreationItem title={`${entity}'s Details`} icon="detailsView" onClick={goToDetails} />
        <PostCreationItem title={`List of ${inflect(entity)(2)}`} icon="listView" onClick={goToList} />
        <PostCreationItem title={`Add New ${entity}`} icon="wizardView" onClick={onCreateNewItem} />
      </Tiles>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;
`;

const Tiles = styled.div`
  display: grid;
  height: 10rem;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${theme.spacing.spacing04};
`;
